/* Ensure the body and HTML fill the viewport */
body, html {
  min-height: 100%; /* Ensures the body and HTML fill the viewport */
  margin: 0;
  display: flex;
  flex-direction: column; /* Allows vertical alignment of elements */
  background-color: #100505;
}

/* Home page container */
.Home {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container fills the viewport height */
  background-color: #100505; /* Fallback color for sides */
  color: black; /* Default text color */
  padding-left: 20px; /* Adds padding to the left */
  box-sizing: border-box; /* Ensures padding doesn't cause overflow */
  margin-top: 5%;
}

/* Logo Style */
.logo {
  width: 12%; /* Adjust size as needed */
  height: auto;
  border-radius: 50%; /* Circular logo */
  margin: auto; /* Center the logo horizontally and vertically */
  display: block;
  position: relative; /* Ensures compatibility with flexbox */
}

/* Header */
.header {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column; /* Stack elements vertically */
  background-color: #100505; /* Optional */
  padding: 0;
  margin: 0;
}

/* FAQ Section */
.faq-section {
  display: flex; /* Aligns the FAQ section horizontally */
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%; /* Ensures it doesn't exceed 100% width */
  padding: 20px;
  color: white;
  box-sizing: border-box; /* Ensures padding doesn't cause overflow */
  margin-top: 5%; /* Moves the FAQ section up by 5% */
}

.faq-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow wrapping if content overflows horizontally */
  width: 100%;
}

.faq-topics {
  flex: 1;
  max-width: 300px; /* Restrict the width of the topics container */
  padding: 10px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow-y: auto;
  box-sizing: border-box; /* Prevent overflow issues */
}

.faq-title {
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.faq-title:hover,
.faq-title.active {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Content display area */
.faq-display {
  flex: 2;
  padding: 20px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-left: 20px;
  max-width: 100%; /* Ensures it doesn't exceed the container's width */
  box-sizing: border-box; /* Prevents overflow */
}

.faq-content {
  font-size: 1rem;
  line-height: 1.5;
}

.faq-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-content li {
  margin-bottom: 10px;
}

/* Link Styles within FAQ content */
.faq-content a {
  color: white; /* Make links white */
  text-decoration: none; /* Remove underline */
}

.faq-content a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
}

/* Button Styles */
.btn-primary {
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 3%;
}

.btn-primary:hover {
  background: rgba(0, 0, 0, 0.6);
  transform: translateY(-2px);
}

.btn-primary:active {
  background: rgba(0, 0, 0, 0.6);
  transform: translateY(1px);
}

.btn-book {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.btn-book:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Footer */
.footer {
  background-color: #100505;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customer-support-message {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  white-space: nowrap; /* Prevents the text from breaking into multiple lines */
}


.home-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Take up full width */
  margin-bottom: 3px; /* Space between home button and form */
}

.home-button {
  background-color: #00BFA6;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 20px rgba(148, 128, 255, 0.5);
}

.home-button:hover {
  background-color: #009C89;
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(148, 128, 255, 0.6);
}

form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 700px;
  background-color: #F4F7FA;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(148, 128, 255, 0.5);
}

.submission-confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center align the content */
  align-items: center;
  padding: 20px;
  text-align: center;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 20px;
}

.submission-confirmation h3 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.submission-confirmation p {
  font-size: 1rem;
  margin-top: 10px;
}

fieldset legend {
  font-size: 1.2rem;
  color: #333333;
  padding: 0 10px;
}

.communications,
.project-links {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"] {
  font-size: 1rem;
  padding: 5px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  width: 90%;
}

button[type="submit"] {
  align-self: center;
  background-color: #7E57C2;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: bold;
  box-shadow: 0 10px 20px rgba(126, 87, 194, 0.5);
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #6A48AF;
  box-shadow: 0 15px 40px rgba(126, 87, 194, 0.6);
  transform: translateY(-3px);
}

.captcha-container {
  display: flex;
  justify-content: center;
}